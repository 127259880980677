<template>
  <div class="index">
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  components: {},
  created() {},
  mounted() {},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.main {
  .el-card {
    margin-bottom: 20px;
    .line-top {
      height: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #229dd6;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 10px;
      p {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
    p {
      font-size: 14px;
      color: #ccc;
    }
  }
}
</style>
